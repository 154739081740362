

export default {
    methods: {
        getNotesShutters(props) {
            return {
                'horse-selection': {
                    name: 'horse-selection',
                    title: this.$t('acte.choix_chevaux'),
                    cmpPath: 'components/Horse/HorseSelectionV2',
                    onOk: (selected) => {
                        this.openHorseNoteAddForm(selected)
                    },
                    props
                },
                'tiers-selection': {
                    name: 'tiers-selection',
                    title: this.$t('tiers.choix_tiers'),
                    cmpPath: 'components/Tiers/TiersSelection',
                    onOk: (selected) => {
                        this.openTiersNoteAddForm(selected)
                    },
                    props
                },
				'note-selection': {
                    name: 'note-selection',
                    title: this.$t('notes.note_selection'),
                    cmpPath: 'ShutterGroomyRoot/Notes/NoteSelection',
                    onOk: (selected) => {
                        this.handleNotePreview([{ note_id: selected[0] }])
                    },
                    props
                },
                'note-add-form': {
                    name: 'note-add-form',
                    title: this.$t('notes.add_note'),
                    cmpPath: 'components/ShutterScreens/Notes/WriteNote',
                    onOk: this.onShuttersSubmit,
                    props
                },
                'note-edit': {
                    name: 'note-edit',
                    title: this.$t('notes.edit_note'),
                    cmpPath: 'components/ShutterScreens/Notes/WriteNote',
                    onOk: this.onShuttersSubmit,
                    props
                },
				'note-preview': {
                    name: 'note-preview',
                    title: this.$t('notes.infos_note'),
                    cmpPath: 'ShutterGroomyRoot/Notes/NotePreview',
                    props
                }
            }
        },

		setupNoteSelection(opts={}) {
			if(opts.notes) {
				const shutters = this.getNotesShutters({ notes: opts.notes, ...opts.shutterProps })
				this.shutterPanel().open(shutters['note-selection'])
			}
			else if(opts.horse_id) {
				// 
			}
		},

        setupHorseNoteAdd(horse_id=0, acte_id=0) {	
			if(horse_id > 0) {
				return this.openHorseNoteAddForm(
					[horse_id], 
					this.$sync.replaceWithReplicated('horse_actes', acte_id)
				)
			}

            const shutters = this.getNotesShutters({
                unique: true
            })
			this.shutterPanel().open(shutters['horse-selection'])
        },

        setupTiersNoteAdd(tiers_id=0) {	
			if(tiers_id > 0) {
				return this.openTiersNoteAddForm([tiers_id])
			}

            const shutters = this.getNotesShutters({
                unique: true
            })
			this.shutterPanel().open(shutters['tiers-selection'])
        },

        openHorseNoteAddForm(selected, acte_id=0) {
			if(selected.length === 0) return 

			const shutters = this.getNotesShutters({
				horse_id: selected[0],
				acte_id: this.$sync.replaceWithReplicated('horse_actes', acte_id)
			})
			this.shutterPanel().open(shutters['note-add-form'])
        },

        openHorseResultNoteAddForm(horse_id, results_ids) {
            const shutters = this.getNotesShutters({horse_id, results_ids})
            this.shutterPanel().open(shutters['note-add-form'])
        },

        openTiersNoteAddForm(selected) {
			if(selected.length === 0) return 

			const shutters = this.getNotesShutters({
				tiers_id: selected[0]
			})
			this.shutterPanel().open(shutters['note-add-form'])
        },

        setupNoteEdit(note_id, horse_id, tiers_id) {
            const shutters = this.getNotesShutters({
                note_id,
				horse_id,
                tiers_id
            })
            this.shutterPanel().open(shutters['note-edit'])
        },

		setupNotePreview(note_id) {
			const shutters = this.getNotesShutters({ note_id })
            this.shutterPanel().open(shutters['note-preview'])
		},

		handleNotePreview(notes) {
			if(notes.length > 1) {
				this.setupNoteSelection({ 
					notes,
					shutterProps: {
						unique: true
					}
				})
				return
			}
			this.setupNotePreview(notes[0].note_id)
		}
    }
}
