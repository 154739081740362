import Vue from 'vue'
import NoteCleaner from '@/assets/js/cache/cleaners/NoteCleaner'
import RootNotesMixin from 'GroomyRoot/mixins/Notes.js'
import Constants from 'Constants'
import Common from '@/assets/js/common.js'
import _cloneDeep from 'lodash/cloneDeep'

export default Vue.util.mergeOptions(RootNotesMixin, {
	methods: {
		getNotes: async function(start_date, end_date) {
			const url = Constants.NOTES_URL+"?licence_key="+Constants.USER_LICENCE_KEY+"&start_date="+start_date+"&end_date="+end_date
			
			return this.$request.request_get_api("NotesMixin::getNotes", url)
				.then(res => res.retour)
				.catch(e => {
					console.error("NotesMixin::getNotes => ", e)
					return null
				})
		},

		getNote: async function(note_id) {
			const url = this.constructRoute(Constants.NOTES_DETAILS_URL, { note_id })+"?licence_key="+Constants.USER_LICENCE_KEY
			
			return this.$request.request_get_api("NotesMixin::getNote", url)
				.then(res => res.retour)
				.catch(e => {
					console.error("NotesMixin::getNote => ", e)
					throw e
				})
		},

		getNoteToNotify: async function() {
			const url = Constants.NOTES_NOTIFY_URL+"?licence_key="+Constants.USER_LICENCE_KEY
			
			return this.$request.request_get_api("NotesMixin::getNoteToNotify", url)
				.then(res => res.retour)
				.catch(e => {
					console.error("NotesMixin::getNoteToNotify => ", e)
					throw e
				})
		},

		getNoteFiles: async function(note_id) {
			const url = this.constructRoute(Constants.NOTES_FILES_URL, { note_id })+"?licence_key="+Constants.USER_LICENCE_KEY
			
			return this.$request.request_get_api("NotesMixin::getNote", url)
				.then(res => res.retour)
				.catch(e => {
					console.error("NotesMixin::getNote => ", e)
					throw e
				})
		},

		saveNote: async function(horse_id, tiers_id, acte_id, title, date, comment, visibility, groups, categories_ids, results_ids) {
			let params = {
				horse_id: horse_id,
				tiers_id: tiers_id,
				licence_key: Constants.USER_LICENCE_KEY,
				title: title,
				date: date,
				comment: comment,
				visibility: visibility,
				groups: groups,
				categories_ids: categories_ids,
				results_ids: results_ids
			}

			acte_id = this.$sync.replaceWithReplicated('horse_actes', acte_id)
			if(acte_id > 0) params.acte_id = acte_id

			const url = this.constructRoute(Constants.NOTES_SET_URL, {})+"?licence_key="+Constants.USER_LICENCE_KEY
			return this.$request.request_post_api("NotesMixin::saveNote", url, params, false)
				.then(res => res.retour)
				.catch(e => {
					console.error("NotesMixin::saveNote => ", e)
					throw e
				})
		},

		saveUpdatedNote: async function(note_id, acte_id, title, date, comment='', visibility, groups, deleted_files=[], categories_ids) {
			let params = { title, date, visibility, groups, categories_ids }
		    
			if(deleted_files.length > 0) params.deleted_files = deleted_files
			if(acte_id > 0) params.acte_id = acte_id
		    if(comment) params.comment = comment

			const url = this.constructRoute(Constants.NOTES_DETAILS_URL, { note_id })+"?licence_key="+Constants.USER_LICENCE_KEY

			return this.$request.request_post_api("NotesMixin::saveNote", url, params, false)
				.then(res => res.retour)
				.catch(e => {
					console.error("NotesMixin::saveNote => ", e)
					throw e
				})
		},

		deleteNotes: async function(note_ids) {
			const url = Constants.NOTES_URL+"?licence_key="+Constants.USER_LICENCE_KEY
			
			return this.$request.request_delete_api("NotesMixin::getNote", url, { note_ids })
				.catch(e => {
					console.error("NotesMixin::getNote => ", e)
					throw e
				})
		},

		getHorseNotes: async function(horse_id, start_date = null, end_date = null) {
			let url = this.constructRoute(Constants.NOTES_HORSE_GET_URL, { horse_id })+"?licence_key="+Constants.USER_LICENCE_KEY

			if(start_date && end_date) {
				url+="&start_date="+start_date+"&end_date="+end_date
			}

			return this.$request.request_get_api("NotesMixin::getHorseNotes", url)
				.then(res => {
					return res.retour
				})
				.catch(e => {
					console.error("NotesMixin::getHorseNotes => ", e)
					throw e
				})
		},

		getTiersNotes: async function(tiers_id, start_date, end_date) {
			const url = this.constructRoute(Constants.NOTES_TIERS_GET_URL, { tiers_id })+"?licence_key="+Constants.USER_LICENCE_KEY+"&start_date="+start_date+"&end_date="+end_date

			return this.$request.request_get_api("NotesMixin::getTiersNotes", url)
				.then(res => {
					return res.retour
				})
				.catch(e => {
					console.error("NotesMixin::getTiersNotes => ", e)
					throw e
				})
		},

		notifyClientNotes(note_ids, model_id) {
			const url = Constants.NOTES_NOTIFY_URL+"?licence_key="+Constants.USER_LICENCE_KEY
            return this.$request.request_post_api("ActesMixin::notify_client", url, { note_ids, model_id }, false)
				.catch(error => {
					console.error("ActesMixin::notify_client => ERROR", error)
					throw error
				})
		},

		updateNoteFilesIndex: async function(note_ids) {
			await NoteCleaner.inst().onMutation(note_ids, ['note_files_types'])
		},

		getAllCategorieNote: function() {
            return this.$storage.db.t('categorie_note')
                .then(table => {
                    return table.toCollection()
                })
                .then(col => {
                    return col.sortBy('categorienote_libelle')
                })
        },

		addCategorie: async function(categorienote_libelle) {
		            await this.$storage.db.t('categorie_note')
		            .then(table => {
		                return table.add({
		                    categorienote_id: Common.getNegativeId(),
		                    categorienote_libelle
		                })
		            })
		        },

        updateCategorie: async function(categorienote_id, categorienote_libelle, categorienote_libelle_en, categorienote_public) {
            await this.$storage.db.t('categorie_note')
            .then(table => {
                return table.update(categorienote_id, {
                    categorienote_libelle,
                    categorienote_libelle_en,
                    categorienote_public
                })
            })
        },

		saveCategoriesOrder: function(categories) {
			let tab_promises = []

			categories.forEach((cat, idx) => {
				tab_promises.push(new Promise((res, _) => {
					// Copie des variables à l'instant T du foreach, pour la résolution de la promise
					const clone = _cloneDeep(cat)
					const order = idx

					this.$storage.db.t('categorie_note')
						.then(table => {
							return table.update(clone.categorienote_id, {
								categorienote_order: order
							})
						})
						.then(() => {
							res()
						})
				}))
			})

            return Promise.all(tab_promises)
        },

        // removeCategorie: async function(categorie_id) {
        // 	let horse_ids = await this.$storage.db.t('horse_categorie_lien')
        //     .then(table => {
        //         return table.where('lien_categorie').equals(categorie_id)
        //     })
		// 	.then(col => {
		// 		return col.toArray()
		// 	})
		// 	.then(categorie => {
		// 		return Array.from(new Set(categorie.map(r => r.lien_horse)))
		// 	})

        //     // Supprimer les liens
        //     await this.$storage.db.t('horse_categorie_lien')
        //     .then(table => {
        //         return table.where('lien_categorie').equals(categorie_id)
        //     })
        //     .then(col => {
        //         return col.delete()
        //     })

        //     // Supprimer la categorie
        //     await this.$storage.db.t('horse_categorie')
        //     .then(table => {
        //         return table.delete(categorie_id)
        //     })
		// 	HorseCategorieCleaner.inst().onMutation([categorie_id])
		// 	HorseCleaner.inst().onMutation(horse_ids, ['horse_categories'])
        // },

		sendMailNotes: async function(notes_ids, model_id, expeditor, recipient_mail) {
			const params = {
				notes_ids: notes_ids,
				model_id: model_id,
				expeditor: expeditor,
				recipient_mail: recipient_mail,
			}
			
			const url = Constants.NOTES_SEND + "?licence_key=" + Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("NotesMixin::sendMailNotes", url, params, false)
				.catch(error => {
					console.error("NotesMixin::sendMailNotes => ERROR", error)
					return null
				})

			return result ? result.retour : null
		} 
	}
})